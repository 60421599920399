<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
import { authHeader } from "../../../helpers/auth-header";

import ConfirmationModal from "@/components/modals/confirmation-modal";
import MessageModal from "@/components/modals/message-modal";

import InputGroup from "@/components/forms/input-group";
import SelectGroup from "@/components/forms/select-group";

import SaveButton from "@/components/buttons/save-button";
import CancelButton from "@/components/buttons/cancel-button";

/**
 * Orders Component
 */
export default {
    components: {
        Layout,
        PageHeader,
        ConfirmationModal,
        MessageModal,
        InputGroup,
        SelectGroup,
        SaveButton,
        CancelButton
    },
    data() {
        const id = this.$route.params.id;
        const modeName = id === 'add' ? 'Add' : 'Edit';

        return {
            id: id,
            title: `${modeName} Tutor Type`,
            modeName,
            items: [
                {
                    text: "Tutor type"
                },
                {
                    text: modeName,
                    active: true,
                },
            ],
            data: {
                name: '',
                rate: '',
                status: 1
            },
            statusOptions: [
                { name: 'Active', value: 1 },
                { name: 'Inactive', value: 0 },
            ],
            errors: {},
        };
    },
    async mounted() {
        if (this.modeName !== 'Add') {
            const result = await axios.get(`${process.env.VUE_APP_APIURL}/field_management/tutor_type/${this.id}`, {
                headers: authHeader(),
            });
            if (result && result.data['code'] === 'success') {
                const data = result.data['data']['tutor_type'];
                for (const [key, item] of Object.entries(data)) {
                    if (key in this.data) {
                        this.data[key] = item;
                    }
                }
            }
        }        
    },
    methods: {
        async save() {
            this.errors = {};
            let result;
            if (this.modeName === 'Add') {
                const requestData = this.data;
                result = await axios.post(`${process.env.VUE_APP_APIURL}/field_management/tutor_type`, requestData, {
                    headers: authHeader(),
                });
            } else {
                const requestData = this.data;
                result = await axios.put(`${process.env.VUE_APP_APIURL}/field_management/tutor_type/${this.id}`, requestData, {
                    headers: authHeader(),
                });
            }

            if (result) {
                if (result.data['code'] === 'success') {
                    if (this.modeName === 'Add') {
                        this.$router.push({ name: "Tutor Type" });
                        // this.$router.back();
                    } else {
                        this.$refs.messageModal.showModal('Your record has been updated successfully');
                    }
                } else if (result.data['code'] === 'invalid_field') {
                    this.errors = result.data['errors'];
                }
            }
        },
        cancel() {
            this.$router.push({ name: "Tutor Type" });
        }
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <ConfirmationModal ref="confirmationModal" />
        <MessageModal ref="messageModal" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body p-4">
                        <div class="row mt-2">
                            <div class="col-12 col-md-4 mb-2">
                                <InputGroup type="text" id="name" displayName="Type of tutor / professional / specialist" v-model="data.name" :error="errors.name" />
                            </div>
                            <div class="col-12 col-md-4 mb-2">
                                <InputGroup type="text" id="rate" displayName="Rates" v-model="data.rate" :error="errors.rate" />
                            </div>
                            <div class="col-12 col-md-4 mb-2">
                                <SelectGroup id="status" displayName="Status" v-model="data.status" :error="errors.status" :options="statusOptions" />
                            </div>
                        </div>

                        <div class="divider"></div>

                        <div class="row mt-2" style="float: right;">
                            <div class="col-12">
                                <CancelButton @click="cancel"></CancelButton>
                                <SaveButton :isSubmit="true" :text="'Save'" @click="save" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>